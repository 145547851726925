import React, { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { Card } from "clutch/src/Card/Card.js";
import { t as gt } from "i18next";

import { IS_APP } from "@/__main__/constants.mjs";
import { appURLs } from "@/app/app-urls.mjs";
import { BuildingList } from "@/game-palworld/components/BuildingList.jsx";
import { BuildingRecipeTable } from "@/game-palworld/components/RecipeTable.jsx";
import {
  WikiLayout,
  WikiLink,
  WikiSidebar,
} from "@/game-palworld/components/wiki.style.jsx";
import type { BuildingCategory } from "@/game-palworld/constants/buildings.mjs";
import {
  BUILDING_CATEGORIES,
  BUILDING_SUB_CATEGORIES,
} from "@/game-palworld/constants/buildings.mjs";
import type {
  Building,
  Technology,
} from "@/game-palworld/models/model-wiki.mjs";
import {
  getWikiData,
  useWikiData,
} from "@/game-palworld/utils/use-wiki-data.mjs";
import BuildingCommon from "@/game-palworld/wiki/buildings-common.mdx";
import { useRoute } from "@/util/router-hooks.mjs";

const BuildingsLanding = ({
  buildings,
  category,
}: {
  buildings: Record<string, Building>;
  category: BuildingCategory;
}) => {
  const buildingList = useMemo(
    () =>
      buildings
        ? Object.values(buildings).filter((b) => b.category === category)
        : null,
    [buildings, category],
  );

  return (
    <WikiLayout title={BUILDING_CATEGORIES[category].label}>
      <div className="main">
        <BuildingList buildings={buildingList} />
      </div>
    </WikiLayout>
  );
};

const Buildings = () => {
  const { t } = useTranslation();
  const {
    parameters: [category, buildingId],
  } = useRoute();
  const { buildings, buildingTech } = useWikiData();

  const building = buildings?.[buildingId];
  if (!buildingId || (buildings && !building))
    return (
      <BuildingsLanding
        buildings={buildings}
        category={category as BuildingCategory}
      />
    );

  if (!building || !buildingTech)
    return (
      <WikiLayout title={["palworld:building", "Building"]}>
        <div className="sidebar">
          <Card loading style={{ height: "460px" }} />
        </div>
        <div className="main">
          <Card loading style={{ height: "184px" }} />
          <BuildingRecipeTable loading />
        </div>
      </WikiLayout>
    );

  const tech = buildingTech[building.techRequirementId];
  return (
    <WikiLayout title={building.label}>
      <div className="sidebar">
        <SidebarCard building={building} tech={tech} />
      </div>
      <div className="main">
        <BuildingCommon
          t={t}
          i18nKey="palworld:items.common"
          building={building}
          technology={tech}
        />
        <BuildingRecipeTable {...building} />
      </div>
    </WikiLayout>
  );
};

const SidebarCard = ({
  building,
  tech,
}: {
  building: Building;
  tech: Technology;
}) => {
  const { t } = useTranslation();

  return (
    <WikiSidebar
      imgSrc={`${appURLs.CDN_PLAIN}/${building.imageUri}`}
      itemName={building.label}
    >
      <WikiSidebar.Info>
        <dt>{t("palworld:item.type", "Type")}</dt>
        <dd>
          <a href={`/palworld/database/buildings/${building.category}`}>
            {t(...BUILDING_CATEGORIES[building.category].label)}
          </a>
        </dd>
        <dt>{t("palworld:item.category", "Category")}</dt>
        <dd>
          <a
            href={`/palworld/database/buildings/${building.category}?category=${building.subCategory}`}
          >
            {t(...BUILDING_SUB_CATEGORIES[building.subCategory].label)}
          </a>
        </dd>
        {!!tech && (
          <>
            <dt>{t("palworld:unlockedBy", "Unlocked By")}</dt>
            <dd>
              <WikiLink href={`technology:${tech.id}`}>{tech.id}</WikiLink>
            </dd>
            <dt>{t("palworld:item.levelRequirement", "Level Req.")}</dt>
            <dd>{tech.levelRequirement.toLocaleString()}</dd>
            <dt>
              {t(
                ...(tech.isAncientTech
                  ? ["palworld:item.ancientPointCost", "Ancient Point Cost"]
                  : ["palworld:item.pointCost", "Tech Point Cost"]),
              )}
            </dt>
            <dd>{tech.pointCost.toLocaleString()}</dd>
          </>
        )}
      </WikiSidebar.Info>
    </WikiSidebar>
  );
};

export function meta([category, buildingId]) {
  const { buildings } = getWikiData();
  const building = buildings?.[buildingId];

  const buildingName = building
    ? gt(...building.label)
    : gt("palworld:building", "Building");
  if (buildingId && (!buildings || building))
    return {
      title: [
        "palworld:buildingSeo.title",
        "{{buildingName}} in Palworld: Complete Profile, Breeding & Strategy Guide | Blitz",
        { buildingName },
      ],
      description: [
        "palworld:buildingLanding.desc",
        "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld buildings.",
      ],
      subtitle: !IS_APP,
    };

  return {
    title: [
      "palworld:buildingLanding.title",
      "Palworld {{category}} Buildings",
      { category: gt(...BUILDING_CATEGORIES[category].label) },
    ],
    description: [
      "palworld:buildingLanding.desc",
      "Unlock the secrets of Palworld with our ultimate guide! Explore comprehensive insights on Palworld buildings.",
    ],
    subtitle: !IS_APP,
  };
}

export default Buildings;
